/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react';
import SimulationProvider from 'providers/SimulationProvider';
import ProgressBar from 'components/ProgressBar';
import Revenu from 'components/Revenu';
import Questions from 'components/Questions';
import QuestionsTags from 'components/QuestionsTags';
import QuestionsText from 'components/QuestionsText';
import QuestionsCheckbox from 'components/QuestionsCheckbox';
import QuestionsDetails from 'components/QuestionsDetails';
import QuestionDevis from 'components/QuestionDevis';
import QuestionAudit from 'components/QuestionAudit';
import QuestionQ45 from 'components/QuestionQ45';
import QuestionsTextAutocomplete from 'components/QuestionsTextAutocomplete';
import QuestionsTextSingle from 'components/QuestionsTextSingle';
import Explanation from 'components/Explanation';
import Success from 'components/Success';
import SuccessMain from 'components/success-v2/SuccessMain';
import Greeting from 'components/Greeting';
import init from 'images/init.svg';
import parcours from 'data/parcours';
import QR38 from 'components/QR38';
import QuestionsEmail from 'components/partenaires/QuestionsEmail';
import QuestionsEmailPhone from 'components/partenaires/QuestionsEmailPhone';
import QuestionsLaforet from 'components/partenaires/QuestionsLaforet';
import QuestionAgentLaforet from 'components/partenaires/QuestionAgentLaforet';
import QuestionsNonPicto from 'components/QuestionsNonPicto';
// import QuestionEmailMandant from 'components/partenaires/QuestionsEmailMandant';
import { useParams, useLocation } from 'react-router-dom';
import dataRoutes from 'data/dataRoutes';
import dataRoutesQ1 from 'data/dataRoutesQ1';
import dataPreQuestions from 'data/partenaires/dataPreQuestions';
import TagManager from 'react-gtm-module';
// import CodePromo from 'components/success/CodePromo';
import Redirect from 'components/Redirect';
import Loader from 'components/Loader';
import ErrorHandler from 'components/ErrorHandler';
import dataSuccess from 'data/dataSuccess';

const CryptoJS = require('crypto-js');

function withParams(Component) {
  // eslint-disable-next-line react/display-name
  return (props) => (
    <Component {...props} params={useParams()} location={useLocation()} />
  );
}

let ENDPOINT;
if (
  window.location.href.includes('dev.') ||
  window.location.href.includes('localhost') ||
  window.location.href.includes('tagenergy.')
) {
  ENDPOINT = process.env.REACT_APP_API_URL_DEV;
} else if (window.location.href.includes('staging.')) {
  ENDPOINT = process.env.REACT_APP_API_URL_STAGING;
} else {
  ENDPOINT = process.env.REACT_APP_API_URL;
}

const HELLIO_EDPOINT = process.env.REACT_APP_HELLIO_API_URL;

const cryptoKEY = process.env.REACT_APP_API_CRYPTO_KEY;
const token = process.env.REACT_APP_API_TOKEN;
const tokenHellio = process.env.REACT_APP_HELLIO_API_TOKEN;

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.clientType = 'particuliers';
    this.state = {
      step: 0,
      stepList: [
        'Q1',
        'Q2',
        'Q3',
        'Q4',
        'Q5',
        'Q6',
        'Q7',
        'Q8',
        'Q9',
        'Q10',
        'Q11',
        'Q12',
        'Q13',
        'Q14',
        'Q15',
        'Q16',
        'Q17',
        'Q18',
        'Q19',
        'Q20',
        // 'Q18','Q19','Q21','Q26','Q27','Q29'
        // 'Q8','Q10','Q15','Q32','Q44'
        // 'Q40','Q39','Q14'
        // 'Q5','Q48','Q26','Q36','Q10','Q18','Q21'
      ],
      currentParcours: '',
      currentOffer: {},
      answers: {},
      syndicContact: {
        syndicSalutation: 'Madame',
        syndicMobilePhone: '',
        syndicEmail: '',
      },
      bailleurContact: { postalcode: '', street: '', city: '' },
      fiscalContact: { postalcode: '', street: '', city: '' },
      address: { postalcode: '', street: '', city: '' },
      contact: {
        salutation: 'Madame',
        mobilephone: '',
        email: '',
        firstname: '',
        lastname: '',
      },
      partenairesAnswers: {
        email: '',
        agentCode: null,
      },
      partenaireMail: '',
      contactValid: false,
      addressValid: false,
      fiscalValid: false,
      syndicValid: false,
      bailleurValid: false,
      success: 'RenovationGlobalesAuditDone',
      comment: '',
      revenuPeople: '1',
      revenuArea: 'IDF',
      Q39: [],
      onForm: false,
      showPopup: false,
      // ⬇ Success states ⬇
      loading: false,
      error: false,
      result: '',
      patchResult: '',
      transaction_id: null,
      contact_id: null,
      // ⬇ Creneau picker states ⬇
      creneau: 'Matin',
      date: new Date(),
      agence: null,
      mandant: null,
      renoTagOffer: null,
      codePromo: '',
      resultSimulation: null,
      resultCodePromo: {
        data: {
          montant_promo: undefined,
          reste_a_charge: undefined,
        },
      },
    };

    this.nextStep = this.nextStep.bind(this);
    this.lastStep = this.lastStep.bind(this);
    this.reset = this.reset.bind(this);
    this.callBtn = this.callBtn.bind(this);
    this.handleCheckbox2 = this.handleCheckbox2.bind(this);
    // this.handleForm = this.handleForm.bind(this);
    this.handleGeneral = this.handleGeneral.bind(this);
    this.handleGeneralNoNext = this.handleGeneralNoNext.bind(this);
    this.handleSyndic = this.handleSyndic.bind(this);
    this.handleBailleur = this.handleBailleur.bind(this);
    this.handleFiscal = this.handleFiscal.bind(this);
    this.handleContact = this.handleContact.bind(this);
    this.handleComment = this.handleComment.bind(this);
    this.handleAddress = this.handleAddress.bind(this);
    this.handleRevenuArea = this.handleRevenuArea.bind(this);
    this.handleRevenuPeople = this.handleRevenuPeople.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.validatePhone = this.validatePhone.bind(this);
    this.simulationAPI = this.simulationAPI.bind(this);
    this.patchAPI = this.patchAPI.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleRenoTagOffer = this.handleRenoTagOffer.bind(this);
    this.onCleanData = this.onCleanData.bind(this);
    // this.runDataLayersEachPage = this.runDataLayersEachPage.bind(this);
  }

  onCleanData = () => {
    this.setState({
      ...this.state,
      contact: {
        firstname: '',
        lastname: '',
        email: '',
        mobilephone: '',
      },
    });
  };

  handleRenoTagOffer = (event) => {
    const selectedLetter = event.target.value;
    const selectedReno = this.state.result.data[
      'Information Rénovation globale'
    ].find((info) => info.letter.join(', ') === selectedLetter);

    this.setState({
      renoTagOffer: selectedReno,
    });
  };

  reset = () => {
    window.location.reload(false);
  };

  handleLastSteep = () => {
    this.runDataLayersEachPage('Q38');
    this.runDataLayers(this.state.stepList[this.state.step]);
    const SL = this.state.stepList;
    if (!SL.includes('Q38')) {
      SL.push('Q38');
    }
    this.nextURL();
    this.setState({
      stepList: SL,
      step: this.state.step + 1,
    });
  };

  callBtn = async () => {
    this.handleLastSteep();
    await this.patchAPI();
  };

  getSuccess = (userAnswer) => {
    const form = { ...parcours };
    for (const question in userAnswer) {
      for (const offer in form) {
        if (
          (form[offer][question] == undefined ||
            form[offer][question].indexOf(userAnswer[question]) === -1) &&
          form[offer][question]?.length !== 0
        ) {
          delete form[offer];
        }
      }
    }
    this.setState({
      stepList: Object.keys(Object.values(form)[0]),
      success: Object.values(form)[0].Success[0],
      currentParcours: Object.keys(form)[0],
    });
  };

  getUrlParams = (options) => {
    const utmParams = {
      utm_campaign: this.state.utm_campaign,
      utm_source: this.state.utm_source,
      utm_medium: this.state.utm_medium,
      utm_content: this.state.utm_content,
      utm_term: this.state.utm_term,
    };

    if (options && options.originedelapiste) {
      utmParams.originedelapiste = this.state.leadsource
        ? encodeURIComponent(this.state.leadsource)
        : undefined;
    }

    const nextURLwithParams = Object.entries(utmParams)
      .filter(([key, value]) => value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    return nextURLwithParams ? `?${nextURLwithParams}` : '';
  };

  nextURL = () => {
    const nextQuestionIdentifier = this.state.stepList[this.state.step + 1];
    const nextURL = this.getRouteOfQuestion(nextQuestionIdentifier);
    const urlParam = this.getUrlParams({ originedelapiste: true });
    let urlPrefix = nextURL;

    // Rajouter le prefix uniquement à la question 1;
    if (this.state.stepList[this.state.step] === 'Q1') {
      const offerUrlPrefix = this.state.currentOffer.urlPrefix ?? '';
      urlPrefix = `${this.clientType}/${offerUrlPrefix}/${nextURL}`;
    }

    const nextURLwithTracking = urlPrefix + `${urlParam}`;

    window.history.pushState(
      {
        step: this.state.step + 1,
        stepList: this.state.stepList,
        answers: this.state.answers,
      },
      'undefined',
      nextURLwithTracking,
    );
  };

  lastURL = (ANS) => {
    const questionIdentifier = this.state.stepList[this.state.step - 1];
    const lastURL = this.getRouteOfQuestion(questionIdentifier);
    const urlParam = this.getUrlParams();
    const lastURLwithTracking = lastURL + `${urlParam}`;

    window.history.pushState(
      {
        step: this.state.step - 1,
        stepList: this.state.stepList,
        answers: ANS,
      },
      undefined,
      lastURLwithTracking,
    );
  };

  lastStepPrequestion = () => {
    if (dataPreQuestions[this.state.leadsource]) {
      this.setState({ stepList: dataPreQuestions[this.state.leadsource] });
    }
  };

  handlePopup = () => {
    this.setState({
      showPopup: true,
    });
  };

  resetStep = () => {
    this.setState({
      stepList: [
        'Q1',
        'Q2',
        'Q3',
        'Q4',
        'Q5',
        'Q6',
        'Q7',
        'Q8',
        'Q9',
        'Q10',
        'Q11',
        'Q12',
        'Q13',
        'Q14',
        'Q15',
        'Q16',
        'Q17',
        'Q18',
        'Q19',
        'Q20',
      ],
      onForm: false,
      showPopup: false,
      step: 0,
    });

    // console.log('resetStep')
  };

  nextStepPartenaire = () => {
    this.setState({
      step: this.state.step + 1,
    });
    // console.log('nextStepPartenaire')
  };

  nextStep = (delay = 0, clearForm = true) => {
    if (this.state.step < this.state.stepList.length - 1) {
      clearTimeout(this.nextStepTimeout);
      this.runDataLayersEachPage(this.state.stepList[this.state.step + 1]);
      this.nextStepTimeout = setTimeout(async () => {
        this.runDataLayers(this.state.stepList[this.state.step]);
        this.nextURL();

        // Envoi des données avant l'affichage de l'étape finale
        if (this.state.stepList[this.state.step + 1] === 'Success') {
          await this.simulationAPI();
        }

        this.setState({
          step: this.state.step + 1,
          ...(clearForm ? { onForm: false } : {}),
        });
      }, delay);
    }
  };

  lastStep = () => {
    const ANS = this.state.answers;
    if (this.state.step !== 0) {
      const currentStep = this.state.stepList[this.state.step];
      delete ANS[currentStep];

      // switch(currentStep) {
      //     // == Reset form data while deletions in answers.
      //     case 'Q18' :
      //         this.setState({ syndicSalutation: 'Madame', syndicMobilePhone: '', syndicEmail:'' });
      //     break;
      //     case 'Q21' :
      //         this.setState({bailleurContact:  {postalcode:'', street:'', city:''} });
      //     break;
      //     case 'Q26' :
      //         this.setState({address:  {postalcode:'', street:'', city:''} });
      //     break;
      //     case 'Q27' :
      //         this.setState({fiscalContact:  {postalcode:'', street:'', city:''} });
      //     break;
      //     case 'Q29' :
      //         this.setState({contact: { salutation: 'Madame', mobilephone: '', email:''}});
      //     break;
      // }
      this.lastURL(ANS);
      this.setState({
        step: this.state.step - 1,
        answers: ANS,
      });
    }
  };

  handleCodePromo = (e) => {
    this.setState({
      codePromo: e.target.value.toUpperCase().replace(/[^a-zA-Z0-9 ]/g, ''),
    });
  };

  validCodePromo = (e) => {
    const url = `${ENDPOINT}/codepromoparrain`;
    const bodyData = {
      transaction_id: this.state.transaction_id,
      code: this.state.codePromo,
      reste_a_charge: this.state.result.data['Reste à charge unitaire (€)']
        ? this.state.result.data['Reste à charge unitaire (€)']
        : undefined,
      nom_offre: this.state.result.data.Offre
        ? this.state.result.data.Offre
        : undefined,
    };

    // console.log('valid code', bodyData)
    const bodyData_encoded = {
      data: CryptoJS.AES.encrypt(
        JSON.stringify(bodyData),
        cryptoKEY,
      ).toString(),
    };
    fetch(url, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token,
      },
      body: JSON.stringify(bodyData_encoded),
    })
      .then((result) => result.json())
      .then(
        (result) => {
          result.data = JSON.parse(
            CryptoJS.AES.decrypt(result.data, cryptoKEY).toString(
              CryptoJS.enc.Utf8,
            ),
          );
          this.setState({ resultCodePromo: result });
        },
        // (error) => {
        //   console.error(error);
        // },
      );
  };

  // handleForm = (name, e) => {
  //     console.log('handle form from main')
  //     console.log(e);
  //     console.log(name)
  // }
  handleGeneral = (e) => {
    const ANS = this.state.answers;
    ANS[e.target.name] = e.target.value;
    this.setState({ answers: ANS });
    this.getSuccess(this.state.answers);

    // Passage à la question suivante directement en 1 clic
    if (
      (e.target.type == 'radio' && e.target.name !== 'Q19') ||
      (e.target.name == 'Q19' && this.state.answers.Q19)
    ) {
      this.nextStep(300, false);
    }
  };

  handleState = (updatedState) => {
    this.setState(updatedState);
  };

  handleGeneralNoNext = (e) => {
    const ANS = this.state.answers;
    ANS[e.target.name] = e.target.value;
    this.setState({ answers: ANS });
    this.getSuccess(this.state.answers);
  };

  handleCheckbox2 = (e) => {
    let LIST = this.state.Q39;
    const ANS = this.state.answers;
    const item = e.target.value;
    if (!LIST.includes(item)) {
      LIST.push(item);
    } else {
      LIST = LIST.filter(function (value) {
        return value != item;
      });
    }
    if (
      LIST.includes('Isolation de combles ou de toitures') &&
      LIST.includes('Isolation des murs')
    ) {
      ANS[e.target.name] = 'Avec Combles et Murs';
      this.setState({ answers: ANS });
    } else if (LIST.includes('Isolation de combles ou de toitures')) {
      ANS[e.target.name] = 'Avec Combles';
      this.setState({ answers: ANS });
    } else if (LIST.includes('Isolation des murs')) {
      ANS[e.target.name] = 'Avec Murs';
      this.setState({ answers: ANS });
    } else if (LIST.length == 0) {
      ANS[e.target.name] = '';
      this.setState({ answers: ANS });
    } else {
      ANS[e.target.name] = 'Sans Combles et Murs';
      this.setState({ answers: ANS });
    }
    this.setState({ Q39: LIST });
    this.getSuccess(this.state.answers);
  };

  validateCodepostal(value) {
    const Reg = /^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/;
    return Reg.test(value);
  }

  validatePhone(value) {
    const phoneReg = /^\d{10}$/;
    return phoneReg.test(value);
  }

  validateEmail(value) {
    const emailReg =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return emailReg.test(value);
  }

  onPlaceSelected = (place, e) => {
    // Google place Selected
    // console.log(place);
    const current = e.dataset.name;
    const ANS = this.state.answers;
    const ADD = { street: '', city: '', postalcode: '' };
    const IDFlist = ['75', '77', '78', '91', '92', '93', '94', '95'];

    place = place.address_components;
    const street_number = place.find((e) => e.types[0] == 'street_number');
    const street_name = place.find((e) => e.types[0] == 'route').long_name;

    if (street_number) {
      ADD.street = street_number.long_name + ' ' + street_name;
    } else {
      ADD.street = street_name;
    }

    ADD.city = place.find((e) => e.types[0] == 'locality').long_name;
    ADD.postalcode = place.find((e) => e.types[0] == 'postal_code').long_name;
    const isIDF = IDFlist.includes(ADD.postalcode.slice(0, 2));
    ANS[current] = ADD;
    const FILLED = Object.values(this.state.answers[current]).filter(
      (element) => {
        return element !== '';
      },
    );

    this.setState({
      answers: ANS,
    });

    switch (current) {
      case 'Q21':
        return this.setState({
          bailleurContact: ADD,
          bailleurValid:
            FILLED.length == 3 &&
            this.validateCodepostal(this.state.answers.Q21.postalcode),
          bailleurShow: true,
        });
      case 'Q26':
        return this.setState({
          address: ADD,
          addressValid:
            FILLED.length == 3 &&
            this.validateCodepostal(this.state.answers.Q26.postalcode),
          revenuArea: isIDF ? 'IDF' : 'Autres',
          addressShow: true,
        });
      case 'Q27':
        return this.setState({
          fiscalContact: ADD,
          fiscalValid:
            FILLED.length == 3 &&
            this.validateCodepostal(this.state.answers.Q27.postalcode),
          revenuArea: isIDF ? 'IDF' : 'Autres',
          fiscalShow: true,
        });
    }
  };

  handlePartenaires = (e) => {
    if (e.target.type == 'email') {
      let ANS = this.state.partenaireMail;
      ANS = e.target.value;

      this.setState({
        partenaireMail: ANS,
        onForm: true,
      });
    }

    if (e.target.type == 'tel') {
      let ANS = this.state.partenairePhone;
      ANS = e.target.value;

      this.setState({
        partenairePhone: ANS,
        onForm: true,
      });
    }
  };

  handleLaForetAgent = (item) => {
    this.setState({
      agence: item,
    });
  };

  handleMandant = (item) => {
    this.setState({
      mandant: item,
    });
  };

  handleSyndic = (e) => {
    const ANS = this.state.answers;
    const SYN = this.state.syndicContact;
    SYN[e.target.name] = e.target.value;
    if (
      e.target.name == 'syndicFirstname' ||
      e.target.name == 'syndicLastname'
    ) {
      SYN[e.target.name] =
        e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    }
    ANS.Q18 = SYN;

    const FILLED = Object.values(this.state.answers.Q18).filter((element) => {
      return element !== '';
    });

    this.setState({
      onForm: FILLED.length == 5,
      syndicContact: SYN,
      answers: ANS,
      syndicValid:
        FILLED.length == 5 &&
        this.validatePhone(this.state.answers.Q18.syndicMobilephone) &&
        this.validateEmail(this.state.answers.Q18.syndicEmail),
    });
  };

  handleComment = (e) => {
    this.setState({ comment: e.target.value });
  };

  handleContact = (e) => {
    const ANS = this.state.answers;
    const CONTACT = this.state.contact;
    CONTACT[e.target.name] = e.target.value;
    if (e.target.name == 'firstname' || e.target.name == 'lastname') {
      CONTACT[e.target.name] =
        e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    }
    ANS.Q29 = CONTACT;

    const FILLED = Object.values(this.state.answers.Q29).filter((element) => {
      return element !== '';
    });
    this.setState({
      onForm: FILLED.length == 5,
      contact: CONTACT,
      answers: ANS,
      contactValid:
        FILLED.length == 5 &&
        this.validatePhone(this.state.answers.Q29.mobilephone) &&
        this.validateEmail(this.state.answers.Q29.email),
    });
  };

  handleContactAutofill = () => {
    const ANS = this.state.answers;
    const CONTACT = this.state.contact;
    const FILLED = Object.values(this.state.contact).filter((element) => {
      return element !== '';
    });
    ANS.Q29 = CONTACT;
    this.setState({
      onForm: FILLED.length == 5,
      contact: CONTACT,
      answers: ANS,
      contactValid:
        FILLED.length == 5 &&
        this.validatePhone(this.state.contact.mobilephone) &&
        this.validateEmail(this.state.contact.email),
    });
  };

  handleBailleur = (e) => {
    const ANS = this.state.answers;
    const BAI = this.state.bailleurContact;
    BAI[e.target.name] = e.target.value;
    ANS.Q21 = BAI;
    const FILLED = Object.values(this.state.answers.Q21).filter((element) => {
      return element !== '';
    });

    this.setState({
      onForm: FILLED.length == 3,
      bailleurContact: BAI,
      answers: ANS,
      bailleurShow: true,
      bailleurValid:
        FILLED.length == 3 && this.validateCodepostal(this.state.answers.Q21.p),
    });
  };

  handleFiscal = (e) => {
    const ANS = this.state.answers;
    const FIS = this.state.fiscalContact;
    FIS[e.target.name] = e.target.value;
    ANS.Q27 = FIS;
    const FILLED = Object.values(this.state.answers.Q27).filter((element) => {
      return element !== '';
    });
    const IDFlist = ['75', '77', '78', '91', '92', '93', '94', '95'];
    const isIDF = IDFlist.includes(
      this.state.fiscalContact.postalcode.slice(0, 2),
    );

    this.setState({
      onForm: FILLED.length == 3,
      fiscalContact: FIS,
      fiscalShow: true,
      answers: ANS,
      fiscalValid:
        FILLED.length == 3 &&
        this.validateCodepostal(this.state.answers.Q27.postalcode),
      revenuArea: isIDF ? 'IDF' : 'Autres',
    });
  };

  handleAddress = (e) => {
    const ANS = this.state.answers;
    const ADD = this.state.address;
    ADD[e.target.name] = e.target.value;
    ANS.Q26 = ADD;
    const FILLED = Object.values(this.state.answers.Q26).filter((element) => {
      return element !== '';
    });
    const IDFlist = ['75', '77', '78', '91', '92', '93', '94', '95'];
    const isIDF = IDFlist.includes(this.state.address.postalcode.slice(0, 2));

    this.setState({
      onForm: FILLED.length == 3,
      address: ADD,
      addressShow: true,
      answers: ANS,
      addressValid:
        FILLED.length == 3 &&
        this.validateCodepostal(this.state.answers.Q26.postalcode),
      revenuArea: isIDF ? 'IDF' : 'Autres',
    });
  };

  handleRevenuPeople = (e) => {
    const ANS = this.state.answers;
    ANS.Q19 = '';
    this.setState({ revenuPeople: e.target.value, answers: ANS });
  };

  handleRevenuArea = (e) => {
    const ANS = this.state.answers;
    ANS.Q19 = '';
    this.setState({ revenuArea: e.target.value, answers: ANS });
  };

  handleFile = (e) => {
    let content = e.target.result;
    content = content.split(',')[1];
    // console.log('file content',  content)
    this.setState({
      file: new Blob([content]).size >= 20971520 ? undefined : content,
    });
  };

  handleFileDevis = (e) => {
    let content = e.target.result;
    content = content.split(',')[1];
    // console.log('file content',  content)
    this.setState({
      fileDevis: new Blob([content]).size >= 20971520 ? undefined : content,
    });
  };

  handleFileAudit = (e) => {
    if (!e) {
      this.setState({
        ...this.state,
        fileAudit: null,
      });
      return;
    }
    let content = e.target.result;
    content = content.split(',')[1];
    this.setState({
      fileAudit: new Blob([content]).size >= 20971520 ? undefined : content,
    });
  };

  handleDate = (date) => {
    // console.log(date)
    this.setState({ date });
  };

  handleCreneau = (e) => {
    this.setState({ creneau: e.target.value });
  };

  handleUserForm = ({ date, creneau, ...contact }) => {
    this.setState({ contact, date, creneau });
  };

  renderBodyData(offre) {
    let IsIDF = this.state.revenuArea == 'IDF';
    let NombreFoyer = this.state.revenuPeople;
    const SL = this.state.stepList;
    if (!SL.includes('Q19')) {
      IsIDF = undefined;
      NombreFoyer = undefined;
    }

    let typeOffre = this.state.answers.Q1;
    const thermostat = typeOffre === 'thermostat';
    if (thermostat) {
      typeOffre = 'Thermostat';
    }
    // if (this.state.answers.Q14 == 'Non') {
    //   typeOffre = 'auditEnergetique';
    // }
    let TypeTravaux = this.state.answers.Q4;
    if (TypeTravaux === 'garage' || TypeTravaux === 'vide') {
      TypeTravaux = "Isolation d'un plancher";
    } else if (!TypeTravaux && this.state.answers.Q7 === 'Chaudière') {
      TypeTravaux = "Installation d'une chaudière";
    } else if (!TypeTravaux && this.state.answers.Q7) {
      TypeTravaux = "Installation d'une pompe à chaleur";
    }

    const typeChaudiere = [
      this.state.answers.Q23,
      this.state.answers.Q24,
      this.state.answers.Q25,
      this.state.answers.Q34,
    ];
    const typeChaudiereResult = typeChaudiere.filter((element) => {
      return element !== undefined;
    });
    const typeCombles = [
      this.state.answers.Q5,
      this.state.answers.Q6,
      this.state.answers.Q7,
      this.state.answers.Q50,
      this.state.answers.Q52,
    ];
    const typeComblesResult = typeCombles.filter((element) => {
      return element !== undefined;
    });

    const RG_Isolation = [
      ...this.state.Q39,
      this.state.answers.Q5,
      this.state.answers.Q6,
    ];
    const RG_IsolationResult = RG_Isolation.filter((element) => {
      return element !== undefined;
    });

    let leadsource = this.state.leadsource;
    if (this.state.leadsource === 'Agent Bricomarche') {
      leadsource = 'Agent Bricomarché';
    }

    return {
      typeOffre,
      Type_de_logement__c: this.state.answers.Q2 || this.state.answers['Q2.V2'],
      ageLogement: this.state.answers.Q3,
      Type_de_travaux__c: TypeTravaux,
      Type_de_combles__c: typeComblesResult[typeComblesResult.length - 1],
      Surface_chauffee__c: this.state.answers.Q8,
      vehiculeRechargeable: this.state.answers.Q9,
      Modele_equipement__c: this.state.answers.Q10,
      Puissance_installation__c: this.state.answers.Q11,
      Distance__c: this.state.answers.Q12,
      Audit_pr_vu__c: this.state.answers.Q14,
      Mensualite__c: this.state.answers.Q15,
      Type_de_contrat__c: this.state.answers.Q16,
      typeProjet: this.state.answers.Q17,
      Nombre_de_personne_dans_le_foyer__c: NombreFoyer,
      Revenu_fiscal__c: this.state.answers.Q19,
      Situation_de_l_occupant__c:
        this.state.answers.Q20 || this.state.answers['Q20.V2'],
      Energie_de_chauffage_principale__c: this.state.answers.Q22,
      Type_de_chaudiere_a_remplacer__c: typeChaudiereResult[0],
      Adresse_du_chantier__c: this.state.address.street,
      Code_Postal_du_chantier__c: this.state.address.postalcode,
      Ville_du_chantier__c: this.state.address.city,
      street: this.state.fiscalContact.street
        ? this.state.fiscalContact.street
        : this.state.address.street,
      city: this.state.fiscalContact.city
        ? this.state.fiscalContact.city
        : this.state.address.city,
      postalcode: this.state.fiscalContact.postalcode
        ? this.state.fiscalContact.postalcode
        : this.state.address.postalcode,
      Travaux_prevus__c: this.state.answers.Q28,
      salutation: this.state.contact.salutation,
      firstname: this.state.contact.firstname,
      lastname: this.state.contact.lastname,
      mobilephone: this.state.contact.mobilephone,
      email: this.state.contact.email,
      typeSimulation: this.state.answers.Q30,
      parrainage_code_parrain__c: this.state.answers.Q32,
      Salutation: this.state.syndicContact.salutation,
      Nom_du_gestionnaire_du_syndic__c: this.state.syndicContact.syndicLastname,
      Prenom_du_gestionnaire_du_syndic__c:
        this.state.syndicContact.syndicFirstname,
      Telephone_du_syndic__c: this.state.syndicContact.syndicMobilephone,
      Email_du_syndic__c: this.state.syndicContact.syndicEmail,
      RG_Isolation: RG_IsolationResult,
      RG_Chauffage: this.state.answers.Q40,
      NewsletterHellio: this.state.answers.Q33,
      IsIDF,
      utm_campaign: this.state.utm_campaign,
      utm_content: this.state.utm_content,
      utm_medium: this.state.utm_medium,
      utm_source: this.state.utm_source,
      utm_term: this.state.utm_term,
      leadsource: leadsource || this.props.marque,
      montant_travaux: this.state.answers.Q43,
      surface_des_combles_m2_c: this.state.answers.Q44,
      typeOffre_prime: this.state.answers.Q45,
      nombre_c: this.state.answers.Q46,
      surface_chauffee__c: this.state.answers.Q47,
      type_audit: this.state.answers.Q48,
      type_fenetre: this.state.answers.Q49,
      magasin_lm__c: this.state.agence ? this.state.agence.value : null,
      email_de_l_interm_diaire__c: this.state.partenaireMail,
      telephone_de_l_intermediaire: this.state.partenairePhone,
      code_agence_laforet: this.state.agence ? this.state.agence.value : null,
      usage: this.state.answers.Q51,
      etiquette_dpe: this.state.answers.Q53,
      date_disponibilite_rappel: this.state.date
        ? this.state.date.toLocaleDateString('fr')
        : undefined,
      creneau_disponibilite_rappel: this.state.creneau
        ? this.state.creneau
        : undefined,
      robinets_thermostatiques_existants:
        typeof this.state.answers.Q54 !== 'undefined'
          ? this.state.answers.Q54 === 'Oui'
          : undefined,
      thermostat_existant: this.state.answers.Q55,
      nombre_emetteur_chaleur:
        typeof this.state.answers.Q56 !== 'undefined'
          ? Number(this.state.answers.Q56)
          : undefined,
      type_de_chauffage__c:
        this.state.answers['Q53.V2'] || (thermostat ? 'Individuel' : undefined),
    };
  }

  simulationAPI = async (handleLastSteep = false) => {
    if (handleLastSteep) {
      this.handleLastSteep();
    }
    this.setState({ loading: true, error: false });
    const bodyData = this.renderBodyData(this.state.success);
    const bodyData_encoded = {
      data: CryptoJS.AES.encrypt(
        JSON.stringify(bodyData),
        cryptoKEY,
      ).toString(),
      fileImposition: this.state.file,
      fileDevis: this.state.fileDevis,
      fileAudit: this.state.fileAudit,
    };

    try {
      if (!this.state.transaction_id) {
        const url = `${ENDPOINT}/getinfos`;
        const promisses = [
          fetch(url, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              token,
            },
            body: JSON.stringify(bodyData_encoded),
          }),
        ];
        const pv = this.state.answers.Q1 === 'photovoltaïque';
        if (pv) {
          promisses.push(
            fetch(`${HELLIO_EDPOINT}/photovoltaique/simulation`, {
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${tokenHellio}`,
              },
            }),
          );
        }

        const [fetchResult, fetchSimulationResult] =
          await Promise.all(promisses);

        const result = await fetchResult.json();
        const simulationResult = fetchSimulationResult
          ? await fetchSimulationResult.json()
          : undefined;

        if (!result.success || (pv && !simulationResult?.success)) {
          throw new Error(result.message ?? simulationResult.message);
        }

        if (typeof result.data === 'string') {
          result.data = JSON.parse(
            CryptoJS.AES.decrypt(result.data, cryptoKEY).toString(
              CryptoJS.enc.Utf8,
            ),
          );
        }
        this.setState({
          loading: false,
          error: false,
          result,
          simulationResult,
          renoTagOffer: result.data?.['Information Rénovation globale']?.[0],
          transaction_id: result.data.hubspot_transactionId,
          contact_id: result.data.hubspot_contactId,
          transaction_document_leads:
            result.data.hubspot_transaction?.properties?.document_leads,
        });
      } else {
        // Update simulation
        const updateBodyData = {
          ...bodyData,
          contactId: this.state.contact_id,
          transactionId: this.state.transaction_id,
          transaction_document_leads: this.state.docLeads,
        };
        const updateBodyData_encoded = {
          data: CryptoJS.AES.encrypt(
            JSON.stringify(updateBodyData),
            cryptoKEY,
          ).toString(),
          fileImposition: this.state.file,
          fileDevis: this.state.fileDevis,
          fileAudit: this.state.fileAudit,
        };

        const url = `${ENDPOINT}/updatesimulation`;

        const fetchResult = await fetch(url, {
          method: 'PATCH',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token,
          },
          body: JSON.stringify(updateBodyData_encoded),
        });
        const result = await fetchResult.json();

        if (!result.success) {
          throw new Error(result.message);
        }

        result.data = JSON.parse(
          CryptoJS.AES.decrypt(result.data, cryptoKEY).toString(
            CryptoJS.enc.Utf8,
          ),
        );

        this.setState({
          loading: false,
          error: false,
          result,
          transaction_id: result.data.hubspot_transactionId,
          contact_id: result.data.hubspot_contactId,
          transaction_document_leads:
            result.data.hubspot_transaction.properties?.document_leads,
        });
      }
    } catch (error) {
      console.warn('simulationAPI', error.message);
      this.setState({
        loading: false,
        error: true,
        errorMessage: error.message,
      });
    }
  };

  async patchAPI() {
    const bodyData = {
      transaction_id: this.state.result.data.hubspot_transactionId,
      transaction_description:
        this.state.result.data.hubspot_transaction?.properties?.description,
      transaction_document_leads:
        this.state.result.data.hubspot_transaction?.properties?.document_leads,
      user_indications: this.state.comment,
      fileImposition: this.state.file,
      user_firstname: this.state.contact.firstname,
      user_lastname: this.state.contact.lastname,
      date_disponibilite_rappel: this.state.date.toLocaleDateString('fr'),
      creneau_disponibilite_rappel: this.state.creneau,
    };

    if (this.state?.renoTagOffer) {
      Object.assign(bodyData, {
        montant_travaux: this.state.renoTagOffer.montant_travaux,
        prime_mpr: this.state.renoTagOffer.prime,
        reste_a_charge:
          // Recalcul du reste à charge pour ne pas prendre en compte le coût de Mon Accompagnateur Rénov
          this.state.renoTagOffer.montant_travaux -
          this.state.renoTagOffer.prime,
      });
    }

    this.setState({ patchBody: bodyData, loading: true, error: false });
    const bodyData_encoded = {
      data: CryptoJS.AES.encrypt(
        JSON.stringify(bodyData),
        cryptoKEY,
      ).toString(),
    };

    const url = `${ENDPOINT}/updatetransaction`;

    try {
      const fetchResult = await fetch(url, {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          token,
        },
        body: JSON.stringify(bodyData_encoded),
      });

      const result = await fetchResult.json();
      if (!result.success) {
        throw new Error(result.message);
      }

      if (typeof result.data === 'string') {
        result.data = JSON.parse(
          CryptoJS.AES.decrypt(result.data, cryptoKEY).toString(
            CryptoJS.enc.Utf8,
          ),
        );
      }
      this.setState({ loading: false, error: false, patchResult: result });
    } catch (error) {
      console.warn('patchAPI', error);
      this.setState({
        loading: false,
        error: true,
        errorMessage: error.message,
      });
    }
  }

  runDataLayers(question) {
    if (this.dataLayers(question)) {
      const tagManagerArgs = {
        dataLayer: this.dataLayers(question),
        dataLayerName: 'simulateurGTM',
      };
      // console.log('tagManagerArgs', tagManagerArgs)
      TagManager.dataLayer(tagManagerArgs);
    }
  }

  runDataLayersEachPage(question) {
    const tagManagerArgs = {
      dataLayer: {
        event: 'Tunnel Particulier -  Question',
        pageUrl: this.getRouteOfQuestion(question),
      },
      dataLayerName: 'simulateurGTM',
    };
    TagManager.dataLayer(tagManagerArgs);
    // console.log('runDataLayerEachPage', question)
  }

  getRouteOfQuestion = (question) => {
    return dataRoutes[question];
  };

  dataLayers(question) {
    switch (question) {
      case 'Q1':
        return {
          event: 'Tunnel Particulier - Home',
          pageUrl: this.getRouteOfQuestion(question),
          typeOffre: this.state.answers.Q1,
          formType: 'Simulateur',
        };

      case 'Q2':
        return {
          event: 'Tunnel Particulier -  Logement',
          pageUrl: this.getRouteOfQuestion(question),
          userHabitation: this.state.answers.Q2,
        };

      case 'Q17':
        return {
          event: 'Tunnel Particulier -  Type Logement',
          pageUrl: this.getRouteOfQuestion(question),
          hellioOffre: this.state.answers.Q17,
        };

      case 'Q20':
        return {
          event: 'Tunnel Particulier -  Type Proprietaire',
          pageUrl: this.getRouteOfQuestion(question),
          userProprietaire: this.state.answers.Q20,
        };
      case 'Q4':
        return {
          event: 'Tunnel Particulier -  Type Isolation',
          pageUrl: this.getRouteOfQuestion(question),
          type_isolation: this.state.answers.Q4,
        };
      case 'Q5':
        return {
          event: 'Tunnel Particulier -  Type Combles',
          pageUrl: this.getRouteOfQuestion(question),
          userCombles: this.state.answers.Q5,
        };
      case 'Q6':
        return {
          event: 'Tunnel Particulier -  Murs',
          pageUrl: this.getRouteOfQuestion(question),
          type_isolation: this.state.answers.Q6,
        };
      case 'Q7':
        return {
          event: 'Tunnel Particulier -  Type Nouveau Chauffage',
          pageUrl: this.getRouteOfQuestion(question),
          userChauffage: this.state.answers.Q7,
        };

      case 'Q22':
        return {
          event: 'Tunnel Particulier -  Energie',
          pageUrl: this.getRouteOfQuestion(question),
          userEnergie: this.state.answers.Q22,
        };

      case 'Q25':
        return {
          event: 'Tunnel Particulier -  Type Equipement',
          pageUrl: this.getRouteOfQuestion(question),
          userEnergieEquipement: this.state.answers.Q25,
        };

      case 'Q26':
        return {
          event: 'Tunnel Particulier -  Adresse Travaux',
          pageUrl: this.getRouteOfQuestion(question),
          userCodePostal: this.state.address.postalcode,
        };

      case 'Q27':
        return {
          event: 'Tunnel Particulier -  Adresse Fiscale',
          pageUrl: this.getRouteOfQuestion(question),
          userCodePostalFiscale: this.state.fiscalContact.postalcode,
        };

      case 'Q29':
        return {
          event: 'Tunnel Particulier -  Lead',
          pageUrl: this.getRouteOfQuestion(question),
          userEmail: this.state.contact.email,
        };

      case 'Q30':
        return {
          event: 'Tunnel Particulier -  Type Devis',
          pageUrl: this.getRouteOfQuestion(question),
          accompagnementDevis: this.state.answers.Q30,
        };
      case 'Success':
        return {
          event: 'Devis Etre Rappele - Simulateur Particulier',
          pageUrl: this.getRouteOfQuestion(question),
        };
      // default :
      //     return {
      //         event: 'Tunnel Particulier -  Question',
      //         pageUrl: dataRoutes[question],
      //     }
    }
  }

  renderLead(current) {
    switch (current) {
      case 'Q53':
        return (
          <QuestionsTags
            name={current}
            handleData={this.handleGeneral}
            data={this.state.answers[current]}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            dataItems={this.props.dataItems}
            leadsource={this.state.leadsource}
            lastStepPrequestion={this.lastStepPrequestion}
          ></QuestionsTags>
        );
      case 'Q4.V2':
        return (
          <Redirect
            name={current}
            handleData={this.handleGeneral}
            data={this.state.answers[current]}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            dataItems={this.props.dataItems}
            leadsource={this.state.leadsource}
            lastStepPrequestion={this.lastStepPrequestion}
          ></Redirect>
        );
      case 'Q50':
      case 'Q52':
        return (
          <QuestionsNonPicto
            name={current}
            handleData={this.handleGeneral}
            data={this.state.answers[current]}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            dataItems={this.props.dataItems}
            leadsource={this.state.leadsource}
            lastStepPrequestion={this.lastStepPrequestion}
          ></QuestionsNonPicto>
        );
      case 'Q45':
        return (
          <QuestionQ45
            name={current}
            handleData={this.handleGeneral}
            data={this.state.answers[current]}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            dataItems={this.props.dataItems}
            leadsource={this.state.leadsource}
            lastStepPrequestion={this.lastStepPrequestion}
            Q1={this.state.answers.Q1}
          ></QuestionQ45>
        );
      case 'Q14':
        return (
          <QuestionAudit
            name={current}
            handleData={this.handleGeneralNoNext}
            data={this.state.answers[current]}
            handleFile={this.handleFileAudit}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            dataItems={this.props.dataItems}
            file={this.state.fileAudit}
          ></QuestionAudit>
        );
      case 'Q5':
      case 'Q48':
        return (
          <QuestionsDetails
            name={current}
            handleData={this.handleGeneral}
            handleData2={this.handleGeneralNoNext}
            data={this.state.answers[current]}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            dataItems={this.props.dataItems}
          ></QuestionsDetails>
        );
      case 'Q8':
      case 'Q10':
      case 'Q15':
      case 'Q32':
      case 'Q44':
      case 'Q46':
      case 'Q47':
      case 'Q56':
        return (
          <QuestionsTextSingle
            name={current}
            userContact={this.state.answers}
            handleData={this.handleGeneral}
            data={this.state.answers[current]}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            dataItems={this.props.dataItems}
          ></QuestionsTextSingle>
        );

      case 'Q18':
        return (
          <QuestionsText
            name="Q18"
            userContact={this.state.syndicContact}
            handleData={this.handleSyndic}
            data={this.state.syndicValid}
            salutation={this.state.syndicContact.syndicSalutation}
            step={this.state.step}
            nextStep={this.nextStep}
            onForm={this.state.onForm}
            lastStep={this.lastStep}
            onCleanData={this.onCleanData}
            phoneValid={this.validatePhone(
              this.state.syndicContact.syndicMobilephone,
            )}
            emailValid={this.validateEmail(
              this.state.syndicContact.syndicEmail,
            )}
            dataItems={this.props.dataItems}
          ></QuestionsText>
        );
      case 'Q19':
        return (
          <Revenu
            name="Q19"
            handleData={this.handleGeneral}
            handleRevenuPeople={this.handleRevenuPeople}
            handleRevenuArea={this.handleRevenuArea}
            revenuPeople={this.state.revenuPeople}
            revenuArea={this.state.revenuArea}
            data={this.state.answers.Q19}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            dataItems={this.props.dataItems}
            globalState={this.state}
          ></Revenu>
        );

      case 'Q21':
        return (
          <QuestionsTextAutocomplete
            name="Q21"
            userContact={this.state.bailleurContact}
            handleData={this.handleBailleur}
            showAddress={this.state.bailleurShow}
            data={this.state.bailleurValid}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            onForm={this.state.onForm}
            onPlaceSelected={this.onPlaceSelected}
            codepostalValid={this.validateCodepostal(
              this.state.bailleurContact.postalcode,
            )}
            dataItems={this.props.dataItems}
          ></QuestionsTextAutocomplete>
        );

      case 'Q26':
        return (
          <QuestionsTextAutocomplete
            name="Q26"
            userContact={this.state.address}
            handleData={this.handleAddress}
            showAddress={this.state.addressShow}
            data={this.state.addressValid}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            onForm={this.state.onForm}
            onPlaceSelected={this.onPlaceSelected}
            codepostalValid={this.validateCodepostal(
              this.state.address.postalcode,
            )}
            dataItems={this.props.dataItems}
          ></QuestionsTextAutocomplete>
        );

      case 'Q27':
        return (
          <QuestionsTextAutocomplete
            name="Q27"
            userContact={this.state.fiscalContact}
            handleData={this.handleFiscal}
            showAddress={this.state.fiscalShow}
            data={this.state.fiscalValid}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            onForm={this.state.onForm}
            onPlaceSelected={this.onPlaceSelected}
            codepostalValid={this.validateCodepostal(
              this.state.fiscalContact.postalcode,
            )}
            dataItems={this.props.dataItems}
          ></QuestionsTextAutocomplete>
        );

      case 'Q29':
        return (
          <QuestionsText
            name="Q29"
            userContact={this.state.contact}
            handleData={this.handleContact}
            data={this.state.contactValid}
            salutation={this.state.contact.salutation}
            handleAutofill={this.handleContactAutofill}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            onForm={this.state.onForm}
            onCleanData={this.onCleanData}
            phoneValid={this.validatePhone(this.state.contact.mobilephone)}
            emailValid={this.validateEmail(this.state.contact.email)}
            dataItems={this.props.dataItems}
          ></QuestionsText>
        );
      case 'Q39':
        return (
          <QuestionsCheckbox
            name={current}
            handleData={this.handleCheckbox2}
            data={this.state.Q39}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            dataItems={this.props.dataItems}
          ></QuestionsCheckbox>
        );
      case 'Q36':
      case 'Q37':
        return (
          <Explanation
            name={current}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            dataItems={this.props.dataItems}
          ></Explanation>
        );
      case 'Q38':
        return (
          <QR38
            lastStep={this.lastStep}
            dataItems={this.props.dataItems}
          ></QR38>
        );
      case 'Q43':
        return (
          <QuestionDevis
            name={current}
            userContact={this.state.answers}
            handleData={this.handleGeneral}
            data={this.state.answers[current]}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            dataItems={this.props.dataItems}
            handleFile={this.handleFileDevis}
          ></QuestionDevis>
        );
      case 'OptimhomeAgent':
      case 'OptimhomeClient':
      case 'CapifranceAgent':
      case 'CapifranceClient':
      case 'ToutvabiensAgent':
      case 'ToutvabiensParticulier':
      case 'LotsAgent':
      case 'LotsADB':
      case 'BricomarcheEmail':
        return (
          <QuestionsEmail
            name={current}
            handleData={this.handlePartenaires}
            data={this.validateEmail(this.state.partenaireMail)}
            step={this.state.step}
            nextStep={this.resetStep}
            onForm={this.state.onForm}
            lastStep={this.lastStep}
            emailValid={this.validateEmail(this.state.partenaireMail)}
            dataItems={this.props.dataItems}
            partenaireMail={this.state.partenaireMail}
            showPopup={this.state.showPopup}
            handlePopup={this.handlePopup}
          ></QuestionsEmail>
        );
      case 'LaforetAgent':
        return (
          <QuestionsLaforet
            name={current}
            handleData={this.handlePartenaires}
            handleLaForetAgent={this.handleLaForetAgent}
            agence={this.state.agence}
            data={
              this.validateEmail(this.state.partenaireMail) && this.state.agence
            }
            step={this.state.step}
            nextStep={this.resetStep}
            onForm={this.state.onForm}
            lastStep={this.lastStep}
            emailValid={this.validateEmail(this.state.partenaireMail)}
            dataItems={this.props.dataItems}
            partenaireMail={this.state.partenaireMail}
            showPopup={this.state.showPopup}
            handlePopup={this.handlePopup}
          ></QuestionsLaforet>
        );
      case 'LaforetClient':
        return (
          <QuestionsLaforet
            name={current}
            handleData={this.handlePartenaires}
            handleLaForetAgent={this.handleLaForetAgent}
            agence={this.state.agence}
            data={this.state.agence}
            step={this.state.step}
            nextStep={this.resetStep}
            onForm={this.state.onForm}
            lastStep={this.lastStep}
            emailValid={this.validateEmail(this.state.partenaireMail)}
            dataItems={this.props.dataItems}
            showPopup={this.state.showPopup}
            handlePopup={this.handlePopup}
          ></QuestionsLaforet>
        );
      case 'LaforetAgentEmailPhone':
        return (
          <QuestionAgentLaforet
            name={current}
            handleData={this.handlePartenaires}
            handleLaForetAgent={this.handleLaForetAgent}
            agence={this.state.agence}
            data={this.state.agence}
            step={this.state.step}
            nextStep={this.nextStepPartenaire}
            onForm={this.state.onForm}
            lastStep={this.lastStep}
            emailValid={this.validateEmail(this.state.partenaireMail)}
            dataItems={this.props.dataItems}
            showPopup={this.state.showPopup}
            handlePopup={this.handlePopup}
          ></QuestionAgentLaforet>
        );
      case 'LaforetEmailPhone':
        return (
          <QuestionsEmailPhone
            name={current}
            handleData={this.handlePartenaires}
            data={this.validateEmail(this.state.partenaireMail)}
            step={this.state.step}
            nextStep={this.resetStep}
            onForm={this.state.onForm}
            lastStep={this.lastStep}
            emailValid={this.validateEmail(this.state.partenaireMail)}
            phoneValid={this.validatePhone(this.state.partenairePhone)}
            dataItems={this.props.dataItems}
            partenaireMail={this.state.partenaireMail}
            partenairePhone={this.state.partenairePhone}
            showPopup={this.state.showPopup}
            handlePopup={this.handlePopup}
          ></QuestionsEmailPhone>
        );
      case 'Success':
        return (
          <Success
            name="Success"
            success={this.state.success}
            handleData={this.handleComment}
            callBtn={this.callBtn}
            simulationAPI={this.simulationAPI}
            comment={this.state.comment}
            answers={this.state.answers}
            reset={this.reset}
            handleFile={this.handleFile}
            loading={this.state.loading}
            error={this.state.error}
            result={this.state.result}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            showFile={this.state.stepList.includes('Q43')}
            creneau={this.state.creneau}
            date={this.state.date}
            contact={this.state.contact}
            handleDate={this.handleDate}
            handleCreneau={this.handleCreneau}
            handleUserForm={this.handleUserForm}
            marqueHost={this.props.marqueHost}
            codePromo={this.state.codePromo}
            handleCodePromo={this.handleCodePromo}
            validCodePromo={this.validCodePromo}
            resultCodePromo={this.state.resultCodePromo}
            renoTagOffer={this.state.renoTagOffer}
            handleRenoTagOffer={this.handleRenoTagOffer}
            showAuditWarning={
              !this.state.fileAudit &&
              this.state.answers.Q1 === 'renovationGlobale'
            }
          ></Success>
        );
      case 'Q1':
      case 'Q2':
      case 'Q3':
      case 'Q4':
      case 'Q6':
      case 'Q7':
      case 'Q9':
      case 'Q11':
      case 'Q12':
      case 'Q13':
      case 'Q16':
      case 'Q17':
      case 'Q20':
      case 'Q22':
      case 'Q23':
      case 'Q24':
      case 'Q25':
      case 'Q28':
      case 'Q30':
      case 'Q31':
      case 'Q33':
      case 'Q34':
      case 'Q40':
      case 'Q49':
      case 'Q51':
      default:
        return (
          <Questions
            name={current}
            handleData={this.handleGeneral}
            data={this.state.answers[current]}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            dataItems={this.props.dataItems}
            leadsource={this.state.leadsource}
            lastStepPrequestion={this.lastStepPrequestion}
            handleState={this.handleState}
          ></Questions>
        );
    }
  }

  UNSAFE_componentWillReceiveProps() {
    const History = window.history.state;
    this.setState({
      ...History,
    });
  }

  componentDidMount() {
    // const infoReno = this.state.result["Information Rénovation globale"];
    // if(infoReno && !this.state.renoTagOffer) {
    //   this.setState({
    //   renoTagOffer: infoReno.length > 0 ? infoReno[0] : null,
    // })
    // }

    const leadsource = new URL(location.href).searchParams.get(
      'originedelapiste',
    );
    this.setState({ leadsource });
    const params = new URL(location.href).searchParams;
    const utm_fields = [
      'utm_campaign',
      'utm_content',
      'utm_medium',
      'utm_source',
      'utm_term',
    ];

    utm_fields.forEach((utm_field) => {
      const value = params.get(utm_field);
      if (value) {
        this.setState({ [utm_field]: value });
      }
    });

    const contactParams = [
      'salutation',
      'firstname',
      'lastname',
      'email',
      'mobilephone',
    ];

    contactParams.forEach((param) => {
      const value = params.get(param);
      const contact = this.state.contact;
      if (value) {
        contact[param] = value;
        this.setState({ contact });
      }
    });

    const minDate = new Date();
    minDate.setDate(minDate.getDate() + 1);
    if (minDate.getDay() == 0 || minDate.getDay() == 6) {
      minDate.setDate(minDate.getDate() + ((1 + 7 - minDate.getDay()) % 7));
      this.setState({ date: minDate });
    }
    this.setState({ date: minDate });

    // let tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
    // if(tomorrow.getDay == 0 || tomorrow.getDay == 6) {
    //   tomorrow = new Date(tomorrow.setDate(tomorrow.getDate() + (1 + 7 - tomorrow.getDay()) % 7));
    // this.setState({ date: tomorrow });
    // } else {
    //   this.setState({ date: tomorrow });
    // }

    if (dataPreQuestions[leadsource]) {
      this.setState({ stepList: dataPreQuestions[leadsource] });
      // console.log(dataPreQuestions[leadsource]);
    }
    this.runDataLayersEachPage('Q1');
    const path = window.location.pathname;

    const hasDataRouteQ1 = dataRoutesQ1[path] && !leadsource;
    const hasOnlyOneQ1Item = this.props.dataItems.Q1.items.length === 1;


    // Si il y'a qu'une seule offre à la question 1
    // Définir automatiquement l'offre comme étant la réponse à la question 1
    if (hasDataRouteQ1 || hasOnlyOneQ1Item) {
      const ANS = this.state.answers;
      ANS.Q1 = hasOnlyOneQ1Item
        ? this.props.dataItems.Q1.items[0].short
        : dataRoutesQ1[path];
      this.setState({ answers: ANS });
      this.getSuccess(this.state.answers);
      this.nextStep(0, false);
    }
  }

  render() {
    // console.clear();
    // console.log('leadsource', this.state.leadsource)
    // console.log(this.state.contact)
    // console.log('partenairePhone',this.state.partenairePhone)
    // console.log('partenaireMail', this.state.partenaireMail)
    // console.log('AGENCE laforet',this.state.agence? this.state.agence.value : null)
    // console.log('pre_question', this.state.pre_question)
    const bodyData = this.renderBodyData(this.state.success);
    // eslint-disable-next-line no-console
    // console.log('bodyData', bodyData);
    // console.log('error', this.state.error);
    // console.log('Body(encrypted)', {
    //   data: CryptoJS.AES.encrypt(
    //     JSON.stringify(bodyData),
    //     cryptoKEY,
    //   ).toString(),
    //   fileImposition: this.state.file,
    //   fileDevis: this.state.fileDevis,
    //   fileAudit: this.state.fileAudit,
    // });
    // const updateBodyData = {
    //   ...bodyData,
    //   contactId: this.state.contact_id,
    //   transactionId: this.state.transaction_id,
    //   transaction_document_leads: this.state.docLeads,
    // };
    // console.log('patchBody', updateBodyData);
    // console.log('result from API', this.state.result);
    // console.log('patch bodyData', this.state.patchBody);
    // console.log('result from PATCH', this.state.patchResult);
    const current = this.state.stepList[this.state.step];

    if (ENDPOINT !== process.env.REACT_APP_API_URL) {
      // console.log('ENDPOINT', ENDPOINT);
      // console.log('PatchBody(encrypted)', updateBodyData_encoded)
      // console.log('marque', this.props.marque)
      // console.log('creneau', this.state.creneau)
      // console.log('date', this.state.date)
      // console.log(this.state.stepList)
      // console.log(this.props.dataItems['Q1']['items'].length == 1);
      // console.log('utm_campaign', this.state.utm_campaign)
      // console.log('utm_medium', this.state.utm_medium)
      // console.log('utm_source', this.state.utm_source)
      // console.log('urlQ1', this.state.urlQ1)
      // console.log('window', window.location)
      // console.log(`Success: ${this.state.success}`)
      //   console.log(this.state.stepList.join(' , '))
      //  console.log(this.state.answers);
      // console.log('getinfos bodyData', this.renderBodyData());
      // console.log('result from API', this.state.result);
      // const typeCombles = [
      //   this.state.answers.Q5,
      //   this.state.answers.Q6,
      //   this.state.answers.Q7,
      //   this.state.answers.Q50,
      //   this.state.answers.Q52,
      // ];
      // const typeComblesResult = typeCombles.filter((element) => {
      //   return element !== undefined;
      // });
      // console.log(
      //   'typeComblesResult',
      //   typeComblesResult,
      //   'last',
      //   typeComblesResult[typeComblesResult.length - 1],
      // );
      //  console.log('patch bodyData', this.state.patchBody);
      //  console.log('result from PATCH', this.state.patchResult)
      // console.log('patch bodyData', this.state.patchBody);
      // console.log('result from PATCH', this.state.patchResult);
      // console.log('renoTagOffer', this.state.renoTagOffer);
      //  console.log('CodePromo', this.state.codePromo)
      // console.log('ResultCodePromo', this.state.resultCodePromo)
      // console.log('BodyCodePromo', {
      //   transaction_id: this.state.transaction_id,
      //   code: this.state.codePromo,
      //   reste_a_charge: this.state.result["data"]? this.state.result["data"]["Reste à charge unitaire (€)"] : undefined,
      // }
      //   )
      // console.log('success', this.state.success);
      //   console.log('file impo', this.state.file)
      // console.log('current Parcours', this.state.currentParcours)
      //  console.log('fileAudit', this.state.fileAudit)
      //  console.log('fileDevis', this.state.fileDevis)
      //   console.log('errorMessage', this.state.errorMessage)
      // console.log(window.location);
      // console.log('RevenuArea', this.state.revenuArea)
      // console.log(this.state.stepList[this.state.step]);
      // console.log('id', this.props.id)
      // localStorage.setItem('step', historyStep)
      // const localStep = localStorage.getItem('step');
      // console.log('local storage step', historyStep)
    }

    const path = dataRoutesQ1[`${window.location.pathname}`]
      ? window.location.pathname
      : '/';

    if (this.state.step === 0) {
      window.history.pushState(
        {
          step: 0,
        },
        undefined,
        `${path}${window.location.search}`,
      );
    }
    let historyStep = window.history.state.step;
    if (!historyStep) {
      historyStep = this.state.step;
    }

    // Loader ou message d'erreur
    if (this.state.loading) {
      return <Loader />;
    } else if (this.state.error) {
      console.warn(this.state.error);
      return <ErrorHandler reset={this.reset}></ErrorHandler>;
    }

    const isSuccess = current === 'Success';

    // Détermine si on affiche la success v2 (SuccessMain) ou pas
    const template = dataSuccess[this.state.success]?.template;
    const isSuccessV2 = isSuccess && template === 'photovoltaique';

    if (isSuccessV2) {
      return (
        <SimulationProvider
          successTemplate={template}
          requestCallBack={this.callBtn}
          lastStep={this.lastStep}
          reset={this.reset}
          answers={this.state.answers}
          result={this.state.result}
          simulationResult={this.state.simulationResult}
          resultCodePromo={this.state.resultCodePromo}
          codePromo={this.state.codePromo}
          handleCodePromo={this.handleCodePromo}
          validCodePromo={this.validCodePromo}
          contact={this.state.contact}
          date={this.state.date}
          creneau={this.state.creneau}
          callBtn={this.callBtn}
          handleDate={this.handleDate}
          handleCreneau={this.handleCreneau}
          simulationAPI={this.simulationAPI}
          handleUserForm={this.handleUserForm}
        >
          <SuccessMain />
        </SimulationProvider>
      );
    }

    return (
      <div>
        <ProgressBar
          step={this.state.step}
          stepList={this.state.stepList}
          moduleData={this.props.moduleData}
          currentStepName={this.state.stepList[this.state.step]}
          reset={isSuccess ? this.reset : null}
        />
        <button
          className="btn-icon btn-init is-hidden-mobile"
          onClick={this.reset}
        >
          Réinitialiser
          <img src={init} alt="Réinitialiser" className="pl-5" />
        </button>
        <div className={isSuccess ? 'whiteBoxSuccess' : 'whiteBoxWeb'}>
          {!isSuccess && current !== 'Q38' && (
            <Greeting
              moduleData={this.props.moduleData}
              step={this.state.step}
              currentStepName={this.state.stepList[this.state.step]}
              dataItems={this.props.dataItems}
            />
          )}
          <div>{this.renderLead(this.state.stepList[historyStep])}</div>
        </div>
      </div>
    );
  }
}

export default withParams(Main);
